import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "c634c71f-089b-485e-b5a0-b842921a2ad8"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "7a34463d-e2c0-4270-9a31-57515ba44070"
export function SecondaryNavC634c71f089b485eB5a0B842921a2ad8(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNavC634c71f089b485eB5a0B842921a2ad8", parentTag)} {...props} />
  );
}

// id: "d333f716-9dc1-40e8-88b1-d85d8cbd1718"
// title: ""
// type: :reference
// key: "header"
// parent_id: "acf8d15d-2704-4b2f-a06f-c83b875ce6c6"
export function HeaderD333f7169dc140e888b1D85d8cbd1718(props: any) {
  return (
    <SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a parentTag="HeaderD333f7169dc140e888b1D85d8cbd1718" title="About Us" {...props} />
  );
}

// id: "ba709bb6-2020-478e-bfba-45ed8259c40b"
// title: "Public Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "bca62c92-f8bf-41e3-bfd9-0b5bca8c343a"
export const NavMenuSlugBa709bb62020478eBfba45ed8259c40b = "marketing-primary-nav";

// id: "108095c1-9f60-4bee-86d7-eccdcb5f88f6"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "dbef23cc-6345-4c00-951a-08ea58140265"
export function Footer108095c19f604bee86d7Eccdcb5f88f6(props: any) {
  return (
    <DefaultFooter03dd003fF3554fb0B67923afec0b44f3 parentTag="Footer108095c19f604bee86d7Eccdcb5f88f6" {...props} />
  );
}

// id: "c92a6ab9-a20e-40f4-b6c7-7b8809ad78ba"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "03dd003f-f355-4fb0-b679-23afec0b44f3"
export function SocialLinksC92a6ab9A20e40f4B6c77b8809ad78ba(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinksC92a6ab9A20e40f4B6c77b8809ad78ba flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.instagram.com/sexposla/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.facebook.com/groups/1104997742859454">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/sexposla">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.sexpositivelosangeles.org/resources/discord">
        <svg width="64" height="71" viewBox="0 0 64 71" fill="none" xmlns="http://www.w3.org/2000/svg" title="Discord icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M7.11111 0C3.22467 0 0 3.19494 0 7.04555V56.3644C0 60.2127 3.22079 63.4062 7.10417 63.4099L48.6875 63.5063C49.2395 63.5064 49.784 63.3792 50.2778 63.1347L58.2014 69.506C58.7228 69.9259 59.3537 70.1908 60.0208 70.27C60.688 70.3493 61.3641 70.2396 61.971 69.9538C62.5778 69.6679 63.0905 69.2175 63.4496 68.6549C63.8087 68.0922 63.9995 67.4402 64 66.7745V7.04555C64 3.19494 60.7753 0 56.8889 0H7.11111ZM7.11111 7.04555H56.8889V59.3711L50.1667 53.97C49.6617 53.5648 49.0543 53.3044 48.4104 53.2172C47.7666 53.1299 47.1109 53.2191 46.5146 53.4752C45.9184 53.7312 45.4043 54.1442 45.0283 54.6693C44.6523 55.1945 44.4288 55.8117 44.3819 56.4538L7.11806 56.3644C7.11574 56.3644 7.11343 56.3644 7.11111 56.3644V7.04555ZM27.1528 18.1643C22.559 18.6011 19.0694 21.3224 19.0694 21.3224C19.0694 21.3224 14.9333 27.2306 14.2222 38.7712C18.4036 43.5058 24.7292 43.5324 24.7292 43.5324L26.0417 41.7985C24.7012 41.3406 23.5587 40.7531 22.3889 39.8583L22.6319 39.1427C24.8186 40.1326 27.6267 40.7114 32 40.7114C36.3733 40.7114 39.1814 40.1291 41.3681 39.1427L41.6111 39.8583C40.4378 40.7566 39.2952 41.3441 37.9583 41.7985L39.2708 43.5324C39.2708 43.5324 45.5964 43.5058 49.7778 38.7712C49.0667 27.2306 44.9306 21.3224 44.9306 21.3224C44.9306 21.3224 41.2277 18.4884 36.8472 18.1643L35.9444 19.9945C34.604 19.7514 33.2053 19.5748 32 19.5748C30.7556 19.5748 29.3572 19.7415 28.0417 19.967L27.1528 18.1643ZM25.5972 28.1822C27.1617 28.1822 28.4444 29.7604 28.4444 31.705C28.4444 33.6495 27.1617 35.2277 25.5972 35.2277C24.0328 35.2277 22.7569 33.6495 22.7569 31.705C22.7569 29.7604 24.0328 28.1822 25.5972 28.1822ZM38.4028 28.1822C39.9672 28.1822 41.2431 29.7604 41.2431 31.705C41.2431 33.6495 39.9672 35.2277 38.4028 35.2277C36.8383 35.2277 35.5556 33.6495 35.5556 31.705C35.5556 29.7604 36.8383 28.1822 38.4028 28.1822Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "16507075-63fe-443d-8f89-da661516444d"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "6167d8ce-3748-4b76-89e3-6debc35c9cf8"
export function Footer1650707563fe443d8f89Da661516444d(props: any) {
  return (
    <DefaultFooter03dd003fF3554fb0B67923afec0b44f3 parentTag="Footer1650707563fe443d8f89Da661516444d" {...props} />
  );
}

// id: "7c53b240-d7b2-4fc5-96fb-4b39593c6024"
// title: "Login Header"
// type: :reference
// key: "header"
// parent_id: "4726d98f-6cfe-4868-9a7c-a03f2387636e"
export function Header7c53b240D7b24fc596fb4b39593c6024(props: any) {
  return (
    <SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a parentTag="Header7c53b240D7b24fc596fb4b39593c6024" title="Login" {...props} />
  );
}

// id: "b452668e-fc3f-4149-990a-76381dd80a97"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "bdfcdca4-6c8d-4d0f-a05f-f20a772295b4"
export function NavigationB452668eFc3f4149990a76381dd80a97(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("NavigationB452668eFc3f4149990a76381dd80a97", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "8b7a9ffc-31cf-4d99-a273-f4a8ed489f1b"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "4dc8ec1a-3744-404d-9a2f-b3e922818db2"
export function HeaderTop8b7a9ffc31cf4d99A273F4a8ed489f1b(props: any) {
  return (
    <DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede parentTag="HeaderTop8b7a9ffc31cf4d99A273F4a8ed489f1b" {...props} />
  );
}

// id: "e964e989-cc82-46ca-9401-2236a7319c9d"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "36140089-9154-4977-b2fe-40700293dbe7"
export const ButtonClassE964e989Cc8246ca94012236a7319c9d = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "5428f046-7814-4166-bd5a-9eb1acfb6dc2"
// title: ""
// type: :html
// key: "logo"
// parent_id: "03dd003f-f355-4fb0-b679-23afec0b44f3"
export function Logo5428f04678144166Bd5a9eb1acfb6dc2(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/spla/images/spla-logo.png" alt="SPLA logo" className={`${parentTag || ""} Logo5428f04678144166Bd5a9eb1acfb6dc2 logo`} />
  );
}

// id: "4394f20c-f6a4-49fe-b1ee-5e333faf4b1f"
// title: ""
// type: :reference
// key: "label"
// parent_id: "e1aa5cdb-52e0-4fe0-b792-24ae7ac529c8"
export function Label4394f20cF6a449feB1ee5e333faf4b1f(props: any) {
  return (
    <DefaultLogoB7cdc3fe0d5c42408df24b72ca7ea945 parentTag="Label4394f20cF6a449feB1ee5e333faf4b1f" {...props} />
  );
}

// id: "c673af5b-f928-46c7-a1d0-1419afe9388d"
// title: "Home Page Body"
// type: :reference
// key: "body"
// parent_id: "6167d8ce-3748-4b76-89e3-6debc35c9cf8"
export function BodyC673af5bF92846c7A1d01419afe9388d(props: any) {
  return (
    <MarketingBody7e0fc815B5de4fd88fb8E67fdd915e5d parentTag="BodyC673af5bF92846c7A1d01419afe9388d" postSlug="home-page-posts2" {...props} />
  );
}

// id: "ea6695e4-3663-42d9-925f-0f48e206fbb4"
// title: ""
// type: :reference
// key: "header"
// parent_id: "ed8d898c-ab94-4969-86dd-983ed87762e5"
export function HeaderEa6695e4366342d9925f0f48e206fbb4(props: any) {
  return (
    <MembersAreaHeader91cd19b10cea4eacAbfb5bdac2d10edc parentTag="HeaderEa6695e4366342d9925f0f48e206fbb4" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "40d6c90f-c55b-4f62-92de-9aae6546dd0e"
// title: ""
// type: :text
// key: "title"
// parent_id: "d333f716-9dc1-40e8-88b1-d85d8cbd1718"
export const Title40d6c90fC55b4f6292de9aae6546dd0e = "About Us";

// id: "60a7002d-6842-485c-a67a-c75f006013f4"
// title: ""
// type: :gf_preset
// key: "hero"
// parent_id: "4dc8ec1a-3744-404d-9a2f-b3e922818db2"
export function Hero60a7002d6842485cA67aC75f006013f4(props: any) {
  const Hero = AppStore.presets["Hero"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Hero parentTag={buildClassName("Hero60a7002d6842485cA67aC75f006013f4", parentTag)} postSlug="home-hero" {...props} />
  );
}

// id: "97a37c1c-5d6f-4240-85f9-94abf71aa79e"
// title: ""
// type: :text
// key: "title"
// parent_id: "4e0e5cf1-8c83-4789-b3f9-4bce0334fc3a"
export const Title97a37c1c5d6f424085f994abf71aa79e = "Join Us";

// id: "9797e4e3-5217-41c6-b7ed-a4a44cac8250"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "db8795d6-93c7-4fc4-8308-27309d51b12c"
export function Footer9797e4e3521741c6B7edA4a44cac8250(props: any) {
  return (
    <DefaultFooter03dd003fF3554fb0B67923afec0b44f3 parentTag="Footer9797e4e3521741c6B7edA4a44cac8250" {...props} />
  );
}

// id: "9433db3d-f276-44ac-a0d1-84b9e8767457"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "0f0b7bea-9f9e-4014-928f-920621b4c1a4"
export const NavMenuSlug9433db3dF27644acA0d184b9e8767457 = "members-primary-nav";

// id: "8d9c278a-1d97-40ea-ba2d-cc5336b6eb3c"
// title: ""
// type: :text
// key: "class"
// parent_id: "17a62ea7-62ec-49a1-a74c-f34c5e4523a6"
export const Class8d9c278a1d9740eaBa2dCc5336b6eb3c = "navigation";

// id: "91cd19b1-0cea-4eac-abfb-5bdac2d10edc"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader91cd19b10cea4eacAbfb5bdac2d10edc(props: any) {
  return (
    <ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4 parentTag="MembersAreaHeader91cd19b10cea4eacAbfb5bdac2d10edc" header-top={<DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede parentTag="HeaderTop0f0b7bea9f9e4014928f920621b4c1a4" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "ad6e2ea5-2dbd-4295-8797-516767fc29c0"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "17a62ea7-62ec-49a1-a74c-f34c5e4523a6"
export const NavMenuSlugAd6e2ea52dbd42958797516767fc29c0 = "marketing-primary-nav";

// id: "ed8d898c-ab94-4969-86dd-983ed87762e5"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefaultEd8d898cAb94496986dd983ed87762e5(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefaultEd8d898cAb94496986dd983ed87762e5 page`}>
      {props["header"] || <MembersAreaHeader91cd19b10cea4eacAbfb5bdac2d10edc parentTag="HeaderEa6695e4366342d9925f0f48e206fbb4" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooter03dd003fF3554fb0B67923afec0b44f3 parentTag="Footer20c5fc9c2ade49c492aa3d6512785489" {...props} />}
    </div>
  );
}

// id: "4012ea12-70d5-4d0f-9965-533409e890b1"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href4012ea1270d54d0f9965533409e890b1 = "/";

// id: "acf8d15d-2704-4b2f-a06f-c83b875ce6c6"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPageAcf8d15d27044b2fA06fC83b875ce6c6(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPageAcf8d15d27044b2fA06fC83b875ce6c6 page gf-page-layout page gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a parentTag="HeaderD333f7169dc140e888b1D85d8cbd1718" title="About Us" {...props} />}
      {props["body"] || <MarketingBody7e0fc815B5de4fd88fb8E67fdd915e5d parentTag="Body61d07730Bdb84eb2947a40c6cd68c6e0" postSlug="about-page-posts" {...props} />}
      {props["footer"] || <DefaultFooter03dd003fF3554fb0B67923afec0b44f3 parentTag="FooterFe03d1e289f84f62A811E95e1066127c" {...props} />}
    </div>
  );
}

// id: "ff30079b-b2e5-41ef-805d-c0472cff40de"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "03dd003f-f355-4fb0-b679-23afec0b44f3"
export function LinksFf30079bB2e541ef805dC0472cff40de(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("LinksFf30079bB2e541ef805dC0472cff40de", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "512dd7c3-d764-4e53-bcfd-112887b86671"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "bdfcdca4-6c8d-4d0f-a05f-f20a772295b4"
export function LinkedLogo512dd7c3D7644e53Bcfd112887b86671(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo512dd7c3D7644e53Bcfd112887b86671", parentTag)} label={<DefaultLogoB7cdc3fe0d5c42408df24b72ca7ea945 parentTag="LabelA446b172Baeb430f81edA8bd6e9c6306" alt="logo" />} {...props} />
  );
}

// id: "6feaa3ee-6d02-44cd-9548-156e1fa43795"
// title: ""
// type: :text
// key: "url"
// parent_id: "e1aa5cdb-52e0-4fe0-b792-24ae7ac529c8"
export const Url6feaa3ee6d0244cd9548156e1fa43795 = "/";

// id: "891c5d72-413a-4835-9147-d5e5a2fda088"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "b452668e-fc3f-4149-990a-76381dd80a97"
export const NavMenuSlug891c5d72413a48359147D5e5a2fda088 = "members-primary-nav";

// id: "6630a819-8c4d-4200-a8ba-20da7da08cb9"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage6630a8198c4d4200A8ba20da7da08cb9(props: any) {
  return (
    <SecondaryPageDbef23cc63454c00951a08ea58140265 parentTag="ResourcesPage6630a8198c4d4200A8ba20da7da08cb9" header={<SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a parentTag="Header827e670a5c9b413a88d91c5d531d71e0" title="Resources" />} {...props} />
  );
}

// id: "4726d98f-6cfe-4868-9a7c-a03f2387636e"
// title: "Login Page"
// type: :html
// key: "login-page"
// parent_id: nil
export function LoginPage4726d98f6cfe48689a7cA03f2387636e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <>
      {props["header"] || <SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a parentTag="Header7c53b240D7b24fc596fb4b39593c6024" title="Login" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter03dd003fF3554fb0B67923afec0b44f3 parentTag="Footer8254dca1F63048f1B27bE166419f1443" {...props} />}
    </>
  );
}

// id: "2c9386c8-211f-498f-abf6-1622624c3a15"
// title: ""
// type: :reference
// key: "header"
// parent_id: "dbef23cc-6345-4c00-951a-08ea58140265"
export function Header2c9386c8211f498fAbf61622624c3a15(props: any) {
  return (
    <SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a parentTag="Header2c9386c8211f498fAbf61622624c3a15" title="Secondary Page" {...props} />
  );
}

// id: "c5f61f18-ca76-442b-874e-74ffc94c4996"
// title: "Home Page postSlug"
// type: :text
// key: "postSlug"
// parent_id: "c673af5b-f928-46c7-a1d0-1419afe9388d"
export const PostSlugC5f61f18Ca76442b874e74ffc94c4996 = "home-page-posts2";

// id: "0bbca8bd-2626-43ea-986a-ea7808c489ec"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "ff30079b-b2e5-41ef-805d-c0472cff40de"
export const NavMenuSlug0bbca8bd262643ea986aEa7808c489ec = "footer-nav";

// id: "0581c345-d001-4cc6-b9ce-092f6625bd13"
// title: "About Us - Post Slug"
// type: :text
// key: "postSlug"
// parent_id: nil
export const PostSlug0581c345D0014cc6B9ce092f6625bd13 = "about-us-posts";

// id: "7e0fc815-b5de-4fd8-8fb8-e67fdd915e5d"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBody7e0fc815B5de4fd88fb8E67fdd915e5d(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBody7e0fc815B5de4fd88fb8E67fdd915e5d page-body`}>
      {props["marketing-cards"] || <MarketingCards parentTag={buildClassName("MarketingCards995ad6a1A13a4023A6b50e3266860bcd", parentTag)} {...props} />}
    </div>
  );
}

// id: "e4900893-e582-4ccb-b39e-6f6faf26a258"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPagesE4900893E5824ccbB39e6f6faf26a258(props: any) {
  return (
    <SecondaryPageDbef23cc63454c00951a08ea58140265 parentTag="SignupPagesE4900893E5824ccbB39e6f6faf26a258" header={<SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a parentTag="HeaderDc62c67d023c4f2382c71361e2a91ab1" title="Join Us" />} {...props} />
  );
}

// id: "7a34463d-e2c0-4270-9a31-57515ba44070"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPages7a34463dE2c042709a3157515ba44070(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPages7a34463dE2c042709a3157515ba44070 gf-page-layout`}>
      {props["header"] || <MembersAreaHeader91cd19b10cea4eacAbfb5bdac2d10edc parentTag="Header767354eb2cca4fe8B88704b43ee917f7" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNavC634c71f089b485eB5a0B842921a2ad8", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter03dd003fF3554fb0B67923afec0b44f3 parentTag="FooterA4f9f6156c0e42d5B4abA26550db5cdc" {...props} />}
    </div>
  );
}

// id: "0f0b7bea-9f9e-4014-928f-920621b4c1a4"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "91cd19b1-0cea-4eac-abfb-5bdac2d10edc"
export function HeaderTop0f0b7bea9f9e4014928f920621b4c1a4(props: any) {
  return (
    <DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede parentTag="HeaderTop0f0b7bea9f9e4014928f920621b4c1a4" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "4e0e5cf1-8c83-4789-b3f9-4bce0334fc3a"
// title: ""
// type: :reference
// key: "header"
// parent_id: "fb0b7ea4-cdef-496a-a00f-4587935596b6"
export function Header4e0e5cf18c834789B3f94bce0334fc3a(props: any) {
  return (
    <SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a parentTag="Header4e0e5cf18c834789B3f94bce0334fc3a" title="Join Us" {...props} />
  );
}

// id: "3dcb8a54-233d-4f6d-83de-be8bb4ac6954"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "ea6695e4-3663-42d9-925f-0f48e206fbb4"
export const NavMenuSlug3dcb8a54233d4f6d83deBe8bb4ac6954 = "members-primary-nav";

// id: "521f30d4-faa2-45e0-b45c-7263b7165e59"
// title: "Password Reset Page"
// type: :reference
// key: "password-reset-page"
// parent_id: nil
export function PasswordResetPage521f30d4Faa245e0B45c7263b7165e59(props: any) {
  return (
    <LoginPage4726d98f6cfe48689a7cA03f2387636e parentTag="PasswordResetPage521f30d4Faa245e0B45c7263b7165e59" {...props} />
  );
}

// id: "5f28363c-f5da-4168-b559-e1a700081add"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPages5f28363cF5da4168B559E1a700081add(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPages5f28363cF5da4168B559E1a700081add gf-page-layout`}>
      {props.children}
    </div>
  );
}

// id: "4dc8ec1a-3744-404d-9a2f-b3e922818db2"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeader4dc8ec1a3744404d9a2fB3e922818db2(props: any) {
  const Hero = AppStore.presets["Hero"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeader4dc8ec1a3744404d9a2fB3e922818db2 site-header`}>
      <div className="background-color" />
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede parentTag="HeaderTop8b7a9ffc31cf4d99A273F4a8ed489f1b" {...props} />}
      </div>
      {props["hero"] || <Hero parentTag={buildClassName("Hero60a7002d6842485cA67aC75f006013f4", parentTag)} postSlug="home-hero" {...props} />}
    </div>
  );
}

// id: "7bce57b2-2002-4e5f-a03a-54f2afdb1300"
// title: ""
// type: :text
// key: "title"
// parent_id: "bca62c92-f8bf-41e3-bfd9-0b5bca8c343a"
export const Title7bce57b220024e5fA03a54f2afdb1300 = null;

// id: "2e98b91e-a0b7-4ba1-a419-0e2bc0d541c6"
// title: ""
// type: :text
// key: "title"
// parent_id: "b035375f-e8aa-4d1a-8988-93a34f52752a"
export const Title2e98b91eA0b74ba1A4190e2bc0d541c6 = "Secondary Marketing Title 1";

// id: "6c45c09d-2360-4d3f-8742-28800108d48d"
// title: ""
// type: :text
// key: "title"
// parent_id: "dc62c67d-023c-4f23-82c7-1361e2a91ab1"
export const Title6c45c09d23604d3f874228800108d48d = "Join Us";

// id: "50f8d515-db59-48a3-a192-051a32a6cd8a"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function Header50f8d515Db5948a3A192051a32a6cd8a(props: any) {
  return (
    <MembersAreaHeader91cd19b10cea4eacAbfb5bdac2d10edc parentTag="Header50f8d515Db5948a3A192051a32a6cd8a" {...props} />
  );
}

// id: "0b3a0921-d85f-4d8b-af06-f9f4fea6e8b4"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label0b3a0921D85f4d8bAf06F9f4fea6e8b4(props: any) {
  return (
    <DefaultLogoB7cdc3fe0d5c42408df24b72ca7ea945 parentTag="Label0b3a0921D85f4d8bAf06F9f4fea6e8b4" {...props} />
  );
}

// id: "0e99f6d4-141e-43a7-b8eb-1b0602eab531"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHero0e99f6d4141e43a7B8eb1b0602eab531(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHero0e99f6d4141e43a7B8eb1b0602eab531 hero`}>
      <div className="title">
        Learn. Play. Grow.
      </div>
      <div className="body">
        Our mission is to change the world by promoting healthy sexuality through community and education.
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("Button0a929183Cc2a49ee81bb71b0bd7dd95b", parentTag)} label="Join Us" href="/join" {...props} />}
      </div>
    </div>
  );
}

// id: "bdfcdca4-6c8d-4d0f-a05f-f20a772295b4"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4 site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo512dd7c3D7644e53Bcfd112887b86671", parentTag)} label={<DefaultLogoB7cdc3fe0d5c42408df24b72ca7ea945 parentTag="LabelA446b172Baeb430f81edA8bd6e9c6306" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("NavigationB452668eFc3f4149990a76381dd80a97", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "b7cdc3fe-0d5c-4240-8df2-4b72ca7ea945"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogoB7cdc3fe0d5c42408df24b72ca7ea945(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/spla/images/spla-logo.png" className={`${parentTag || ""} DefaultLogoB7cdc3fe0d5c42408df24b72ca7ea945 logo`} alt="logo" />
  );
}

// id: "bcf66754-494e-412e-8f7c-a08a1a45188d"
// title: "Secondary Marketing Header Reference"
// type: :reference
// key: "header"
// parent_id: nil
export function HeaderBcf66754494e412e8f7cA08a1a45188d(props: any) {
  return (
    <SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a parentTag="HeaderBcf66754494e412e8f7cA08a1a45188d" {...props} />
  );
}

// id: "307b5206-4f74-4f78-b469-b019dbbd8e21"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote307b52064f744f78B469B019dbbd8e21(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote307b52064f744f78B469B019dbbd8e21`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "dd5f347c-f132-483c-892c-c1671cd996d0"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function FooterDd5f347cF132483c892cC1671cd996d0(props: any) {
  return (
    <DefaultFooter03dd003fF3554fb0B67923afec0b44f3 parentTag="FooterDd5f347cF132483c892cC1671cd996d0" {...props} />
  );
}

// id: "dbef23cc-6345-4c00-951a-08ea58140265"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPageDbef23cc63454c00951a08ea58140265(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPageDbef23cc63454c00951a08ea58140265 gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a parentTag="Header2c9386c8211f498fAbf61622624c3a15" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooter03dd003fF3554fb0B67923afec0b44f3 parentTag="Footer108095c19f604bee86d7Eccdcb5f88f6" {...props} />}
    </div>
  );
}

// id: "089a7791-f5b3-4d5f-b437-b1ba7eb5736e"
// title: ""
// type: :reference
// key: "header"
// parent_id: "db8795d6-93c7-4fc4-8308-27309d51b12c"
export function Header089a7791F5b34d5fB437B1ba7eb5736e(props: any) {
  return (
    <SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a parentTag="Header089a7791F5b34d5fB437B1ba7eb5736e" title="Public Events" {...props} />
  );
}

// id: "4747cba1-20fb-4d75-90c6-dd448039ae48"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "b035375f-e8aa-4d1a-8988-93a34f52752a"
export function HeaderTop4747cba120fb4d7590c6Dd448039ae48(props: any) {
  return (
    <DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede parentTag="HeaderTop4747cba120fb4d7590c6Dd448039ae48" {...props} />
  );
}

// id: "570dacac-d4fe-4304-99be-d86c38cde521"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages570dacacD4fe430499beD86c38cde521(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages570dacacD4fe430499beD86c38cde521 gf-page-layout`}>
      {props["header"] || <MembersAreaHeader91cd19b10cea4eacAbfb5bdac2d10edc parentTag="HeaderE915e08b792744c29c008e5bb73168bf" {...props} />}
      {props.children}
    </div>
  );
}

// id: "fb0b7ea4-cdef-496a-a00f-4587935596b6"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPageFb0b7ea4Cdef496aA00f4587935596b6(props: any) {
  return (
    <SecondaryPageDbef23cc63454c00951a08ea58140265 parentTag="JoinUsPageFb0b7ea4Cdef496aA00f4587935596b6" header={<SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a parentTag="Header4e0e5cf18c834789B3f94bce0334fc3a" title="Join Us" />} {...props} />
  );
}

// id: "bca62c92-f8bf-41e3-bfd9-0b5bca8c343a"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeaderBca62c92F8bf41e3Bfd90b5bca8c343a(props: any) {
  return (
    <ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4 parentTag="DefaultHeaderBca62c92F8bf41e3Bfd90b5bca8c343a" navMenuSlug="marketing-primary-nav" title {...props} />
  );
}

// id: "db8795d6-93c7-4fc4-8308-27309d51b12c"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPageDb8795d693c74fc4830827309d51b12c(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPageDb8795d693c74fc4830827309d51b12c gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a parentTag="Header089a7791F5b34d5fB437B1ba7eb5736e" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter03dd003fF3554fb0B67923afec0b44f3 parentTag="Footer9797e4e3521741c6B7edA4a44cac8250" {...props} />}
    </div>
  );
}

// id: "6ee102a9-fbcb-4c0d-951c-a112ef64f8be"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "bdfcdca4-6c8d-4d0f-a05f-f20a772295b4"
export const BackgroundImage6ee102a9Fbcb4c0d951cA112ef64f8be = "https://mp1md-pub.s3.amazonaws.com/spla/images/spla-skyline.jpeg";

// id: "0a929183-cc2a-49ee-81bb-71b0bd7dd95b"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "0e99f6d4-141e-43a7-b8eb-1b0602eab531"
export function Button0a929183Cc2a49ee81bb71b0bd7dd95b(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button0a929183Cc2a49ee81bb71b0bd7dd95b", parentTag)} label="Join Us" href="/join" {...props} />
  );
}

// id: "fa4e2846-0ab3-4b87-b301-3b315ad08992"
// title: ""
// type: :text
// key: "alt"
// parent_id: "b7cdc3fe-0d5c-4240-8df2-4b72ca7ea945"
export const AltFa4e28460ab34b87B3013b315ad08992 = "logo";

// id: "767354eb-2cca-4fe8-b887-04b43ee917f7"
// title: ""
// type: :reference
// key: "header"
// parent_id: "7a34463d-e2c0-4270-9a31-57515ba44070"
export function Header767354eb2cca4fe8B88704b43ee917f7(props: any) {
  return (
    <MembersAreaHeader91cd19b10cea4eacAbfb5bdac2d10edc parentTag="Header767354eb2cca4fe8B88704b43ee917f7" {...props} />
  );
}

// id: "6aae4e26-a5a7-4b7e-9000-a61dff8736e7"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "03dd003f-f355-4fb0-b679-23afec0b44f3"
export function Copyright6aae4e26A5a74b7e9000A61dff8736e7(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright6aae4e26A5a74b7e9000A61dff8736e7", parentTag)} {...props} />
  );
}

// id: "a567f639-82cf-4e5e-89a8-a94c4d495528"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "6167d8ce-3748-4b76-89e3-6debc35c9cf8"
export function WelcomeBackA567f63982cf4e5e89a8A94c4d495528(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBackA567f63982cf4e5e89a8A94c4d495528", parentTag)} {...props} />
  );
}

// id: "0237d48b-cceb-4a4e-b383-e5653afb2068"
// title: ""
// type: :text
// key: "title"
// parent_id: "d8be0745-d5c2-45e7-8479-24c6a00522e4"
export const Title0237d48bCceb4a4eB383E5653afb2068 = "Default Title";

// id: "2b2f9877-714f-474f-a816-7a9637d853ee"
// title: ""
// type: :html
// key: "quote"
// parent_id: "6167d8ce-3748-4b76-89e3-6debc35c9cf8"
export function Quote2b2f9877714f474fA8167a9637d853ee(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} Quote2b2f9877714f474fA8167a9637d853ee PageQuote`}>
      <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/fDIDlI3qk75Cn3iS_regular.jpeg" className="image opacity" />
      <div className="TextContent">
        Our mission is to change the world by promoting healthy sexuality through community and education.
      </div>
    </div>
  );
}

// id: "fea8e3b6-2a84-47bc-a3c7-52079088de67"
// title: ""
// type: :text
// key: "title"
// parent_id: "92b4bb0b-c6f8-4d13-8acf-fc05780e060d"
export const TitleFea8e3b62a8447bcA3c752079088de67 = "Secondary Marketing Title 2";

// id: "dd6e6f71-84d9-461d-9b10-2f379926cde2"
// title: ""
// type: :text
// key: "title"
// parent_id: "827e670a-5c9b-413a-88d9-1c5d531d71e0"
export const TitleDd6e6f7184d9461d9b102f379926cde2 = "Resources";

// id: "8873d4db-abc2-4cee-978b-64a22fc57a92"
// title: ""
// type: :text
// key: "label"
// parent_id: "0a929183-cc2a-49ee-81bb-71b0bd7dd95b"
export const Label8873d4dbAbc24cee978b64a22fc57a92 = "Join Us";

// id: "6167d8ce-3748-4b76-89e3-6debc35c9cf8"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePage6167d8ce37484b7689e36debc35c9cf8(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePage6167d8ce37484b7689e36debc35c9cf8 page gf-page-layout page gf-page-layout`}>
      {props["header"] || <HomeHeader4dc8ec1a3744404d9a2fB3e922818db2 parentTag="Header74b1bfbc6dd74db7Bf17661f3b4c227a" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBackA567f63982cf4e5e89a8A94c4d495528", parentTag)} {...props} />}
      {props["body"] || <MarketingBody7e0fc815B5de4fd88fb8E67fdd915e5d parentTag="BodyC673af5bF92846c7A1d01419afe9388d" postSlug="home-page-posts2" {...props} />}
      {props["quote"] || <Quote2b2f9877714f474fA8167a9637d853ee />}
      {props["footer"] || <DefaultFooter03dd003fF3554fb0B67923afec0b44f3 parentTag="Footer1650707563fe443d8f89Da661516444d" {...props} />}
    </div>
  );
}

// id: "8254dca1-f630-48f1-b27b-e166419f1443"
// title: "Login Footer"
// type: :reference
// key: "footer"
// parent_id: "4726d98f-6cfe-4868-9a7c-a03f2387636e"
export function Footer8254dca1F63048f1B27bE166419f1443(props: any) {
  return (
    <DefaultFooter03dd003fF3554fb0B67923afec0b44f3 parentTag="Footer8254dca1F63048f1B27bE166419f1443" {...props} />
  );
}

// id: "e1aa5cdb-52e0-4fe0-b792-24ae7ac529c8"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "a1b211b2-11a2-4d8a-adc7-dd1c8dd23ede"
export function LinkedLogoE1aa5cdb52e04fe0B79224ae7ac529c8(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogoE1aa5cdb52e04fe0B79224ae7ac529c8", parentTag)} label={<DefaultLogoB7cdc3fe0d5c42408df24b72ca7ea945 parentTag="Label4394f20cF6a449feB1ee5e333faf4b1f" alt="logo" />} url="/" className="linked-logo" {...props} />
  );
}

// id: "827e670a-5c9b-413a-88d9-1c5d531d71e0"
// title: ""
// type: :reference
// key: "header"
// parent_id: "6630a819-8c4d-4200-a8ba-20da7da08cb9"
export function Header827e670a5c9b413a88d91c5d531d71e0(props: any) {
  return (
    <SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a parentTag="Header827e670a5c9b413a88d91c5d531d71e0" title="Resources" {...props} />
  );
}

// id: "5275fc9c-ee8c-4bda-8011-cb162b5b99c5"
// title: ""
// type: :text
// key: "title"
// parent_id: "089a7791-f5b3-4d5f-b437-b1ba7eb5736e"
export const Title5275fc9cEe8c4bda8011Cb162b5b99c5 = "Public Events";

// id: "b035375f-e8aa-4d1a-8988-93a34f52752a"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a site-header`}>
      <div className="background-color" />
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede parentTag="HeaderTop4747cba120fb4d7590c6Dd448039ae48" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHeroD8be0745D5c245e7847924c6a00522e4 parentTag="Hero92b4bb0bC6f84d138acfFc05780e060d" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "20c5fc9c-2ade-49c4-92aa-3d6512785489"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "ed8d898c-ab94-4969-86dd-983ed87762e5"
export function Footer20c5fc9c2ade49c492aa3d6512785489(props: any) {
  return (
    <DefaultFooter03dd003fF3554fb0B67923afec0b44f3 parentTag="Footer20c5fc9c2ade49c492aa3d6512785489" {...props} />
  );
}

// id: "17a62ea7-62ec-49a1-a74c-f34c5e4523a6"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "a1b211b2-11a2-4d8a-adc7-dd1c8dd23ede"
export function Navigation17a62ea762ec49a1A74cF34c5e4523a6(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation17a62ea762ec49a1A74cF34c5e4523a6", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "4acb01ff-98a6-4579-9c33-80bd5379f30b"
// title: "Login Page Title"
// type: :text
// key: "title"
// parent_id: "7c53b240-d7b2-4fc5-96fb-4b39593c6024"
export const Title4acb01ff98a645799c3380bd5379f30b = "Login";

// id: "d8be0745-d5c2-45e7-8479-24c6a00522e4"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHeroD8be0745D5c245e7847924c6a00522e4(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeroD8be0745D5c245e7847924c6a00522e4 hero`}>
      <div className="title">
        {props["title"] || Title0237d48bCceb4a4eB383E5653afb2068}
      </div>
    </div>
  );
}

// id: "74b1bfbc-6dd7-4db7-bf17-661f3b4c227a"
// title: ""
// type: :reference
// key: "header"
// parent_id: "6167d8ce-3748-4b76-89e3-6debc35c9cf8"
export function Header74b1bfbc6dd74db7Bf17661f3b4c227a(props: any) {
  return (
    <HomeHeader4dc8ec1a3744404d9a2fB3e922818db2 parentTag="Header74b1bfbc6dd74db7Bf17661f3b4c227a" {...props} />
  );
}

// id: "fe03d1e2-89f8-4f62-a811-e95e1066127c"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "acf8d15d-2704-4b2f-a06f-c83b875ce6c6"
export function FooterFe03d1e289f84f62A811E95e1066127c(props: any) {
  return (
    <DefaultFooter03dd003fF3554fb0B67923afec0b44f3 parentTag="FooterFe03d1e289f84f62A811E95e1066127c" {...props} />
  );
}

// id: "a4f9f615-6c0e-42d5-b4ab-a26550db5cdc"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "7a34463d-e2c0-4270-9a31-57515ba44070"
export function FooterA4f9f6156c0e42d5B4abA26550db5cdc(props: any) {
  return (
    <DefaultFooter03dd003fF3554fb0B67923afec0b44f3 parentTag="FooterA4f9f6156c0e42d5B4abA26550db5cdc" {...props} />
  );
}

// id: "0ead4175-158c-46db-9fb9-26e344c1ecfd"
// title: ""
// type: :text
// key: "href"
// parent_id: "0a929183-cc2a-49ee-81bb-71b0bd7dd95b"
export const Href0ead4175158c46db9fb926e344c1ecfd = "/join";

// id: "36140089-9154-4977-b2fe-40700293dbe7"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCards3614008991544977B2fe40700293dbe7(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards3614008991544977B2fe40700293dbe7", parentTag)} buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "e915e08b-7927-44c2-9c00-8e5bb73168bf"
// title: ""
// type: :reference
// key: "header"
// parent_id: "570dacac-d4fe-4304-99be-d86c38cde521"
export function HeaderE915e08b792744c29c008e5bb73168bf(props: any) {
  return (
    <MembersAreaHeader91cd19b10cea4eacAbfb5bdac2d10edc parentTag="HeaderE915e08b792744c29c008e5bb73168bf" {...props} />
  );
}

// id: "92b4bb0b-c6f8-4d13-8acf-fc05780e060d"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "b035375f-e8aa-4d1a-8988-93a34f52752a"
export function Hero92b4bb0bC6f84d138acfFc05780e060d(props: any) {
  return (
    <SecondaryMarketingHeroD8be0745D5c245e7847924c6a00522e4 parentTag="Hero92b4bb0bC6f84d138acfFc05780e060d" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "a446b172-baeb-430f-81ed-a8bd6e9c6306"
// title: ""
// type: :reference
// key: "label"
// parent_id: "512dd7c3-d764-4e53-bcfd-112887b86671"
export function LabelA446b172Baeb430f81edA8bd6e9c6306(props: any) {
  return (
    <DefaultLogoB7cdc3fe0d5c42408df24b72ca7ea945 parentTag="LabelA446b172Baeb430f81edA8bd6e9c6306" {...props} />
  );
}

// id: "2730eded-900b-439a-90b9-b39ccb6d4351"
// title: "About Page PostSlug"
// type: :text
// key: "postSlug"
// parent_id: "61d07730-bdb8-4eb2-947a-40c6cd68c6e0"
export const PostSlug2730eded900b439a90b9B39ccb6d4351 = "about-page-posts";

// id: "a1b211b2-11a2-4d8a-adc7-dd1c8dd23ede"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogoE1aa5cdb52e04fe0B79224ae7ac529c8", parentTag)} label={<DefaultLogoB7cdc3fe0d5c42408df24b72ca7ea945 parentTag="Label4394f20cF6a449feB1ee5e333faf4b1f" alt="logo" />} url="/" className="linked-logo" {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation17a62ea762ec49a1A74cF34c5e4523a6", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
    </div>
  );
}

// id: "4304dcdf-fda2-4270-bd54-2d77e07e2835"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "03dd003f-f355-4fb0-b679-23afec0b44f3"
export function Groupflow4304dcdfFda24270Bd542d77e07e2835(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("Groupflow4304dcdfFda24270Bd542d77e07e2835", parentTag)} {...props} />
  );
}

// id: "e9ae0ca8-4539-46ba-be6e-d91ec86f99df"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "03dd003f-f355-4fb0-b679-23afec0b44f3"
export function SocialLinksE9ae0ca8453946baBe6eD91ec86f99df(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinksE9ae0ca8453946baBe6eD91ec86f99df flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.instagram.com/sexposla/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.facebook.com/groups/1104997742859454">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/sexposla">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.sexpositivelosangeles.org/resources/discord">
        <svg width="64" height="71" viewBox="0 0 64 71" fill="none" xmlns="http://www.w3.org/2000/svg" title="Discord icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M7.11111 0C3.22467 0 0 3.19494 0 7.04555V56.3644C0 60.2127 3.22079 63.4062 7.10417 63.4099L48.6875 63.5063C49.2395 63.5064 49.784 63.3792 50.2778 63.1347L58.2014 69.506C58.7228 69.9259 59.3537 70.1908 60.0208 70.27C60.688 70.3493 61.3641 70.2396 61.971 69.9538C62.5778 69.6679 63.0905 69.2175 63.4496 68.6549C63.8087 68.0922 63.9995 67.4402 64 66.7745V7.04555C64 3.19494 60.7753 0 56.8889 0H7.11111ZM7.11111 7.04555H56.8889V59.3711L50.1667 53.97C49.6617 53.5648 49.0543 53.3044 48.4104 53.2172C47.7666 53.1299 47.1109 53.2191 46.5146 53.4752C45.9184 53.7312 45.4043 54.1442 45.0283 54.6693C44.6523 55.1945 44.4288 55.8117 44.3819 56.4538L7.11806 56.3644C7.11574 56.3644 7.11343 56.3644 7.11111 56.3644V7.04555ZM27.1528 18.1643C22.559 18.6011 19.0694 21.3224 19.0694 21.3224C19.0694 21.3224 14.9333 27.2306 14.2222 38.7712C18.4036 43.5058 24.7292 43.5324 24.7292 43.5324L26.0417 41.7985C24.7012 41.3406 23.5587 40.7531 22.3889 39.8583L22.6319 39.1427C24.8186 40.1326 27.6267 40.7114 32 40.7114C36.3733 40.7114 39.1814 40.1291 41.3681 39.1427L41.6111 39.8583C40.4378 40.7566 39.2952 41.3441 37.9583 41.7985L39.2708 43.5324C39.2708 43.5324 45.5964 43.5058 49.7778 38.7712C49.0667 27.2306 44.9306 21.3224 44.9306 21.3224C44.9306 21.3224 41.2277 18.4884 36.8472 18.1643L35.9444 19.9945C34.604 19.7514 33.2053 19.5748 32 19.5748C30.7556 19.5748 29.3572 19.7415 28.0417 19.967L27.1528 18.1643ZM25.5972 28.1822C27.1617 28.1822 28.4444 29.7604 28.4444 31.705C28.4444 33.6495 27.1617 35.2277 25.5972 35.2277C24.0328 35.2277 22.7569 33.6495 22.7569 31.705C22.7569 29.7604 24.0328 28.1822 25.5972 28.1822ZM38.4028 28.1822C39.9672 28.1822 41.2431 29.7604 41.2431 31.705C41.2431 33.6495 39.9672 35.2277 38.4028 35.2277C36.8383 35.2277 35.5556 33.6495 35.5556 31.705C35.5556 29.7604 36.8383 28.1822 38.4028 28.1822Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "77d2f2f9-d8c7-43e7-9648-93c99e0fa3a7"
// title: ""
// type: :text
// key: "title"
// parent_id: "2c9386c8-211f-498f-abf6-1622624c3a15"
export const Title77d2f2f9D8c743e7964893c99e0fa3a7 = "Secondary Page";

// id: "03dd003f-f355-4fb0-b679-23afec0b44f3"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooter03dd003fF3554fb0B67923afec0b44f3(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooter03dd003fF3554fb0B67923afec0b44f3 footer`}>
      {props["social-links"] || <SocialLinks71f6c31a34dd44d5805e501d9b1e6db8 />}
      {props["links"] || <Navigation parentTag={buildClassName("LinksFf30079bB2e541ef805dC0472cff40de", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <Logo5428f04678144166Bd5a9eb1acfb6dc2 />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("Copyright6aae4e26A5a74b7e9000A61dff8736e7", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("Groupflow4304dcdfFda24270Bd542d77e07e2835", parentTag)} {...props} />}
    </div>
  );
}

// id: "71f6c31a-34dd-44d5-805e-501d9b1e6db8"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "03dd003f-f355-4fb0-b679-23afec0b44f3"
export function SocialLinks71f6c31a34dd44d5805e501d9b1e6db8(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinks71f6c31a34dd44d5805e501d9b1e6db8 flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.instagram.com/sexposla/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.facebook.com/groups/1104997742859454">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/sexposla">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.sexpositivelosangeles.org/resources/discord">
        <svg width="64" height="71" viewBox="0 0 64 71" fill="none" xmlns="http://www.w3.org/2000/svg" title="Discord icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M7.11111 0C3.22467 0 0 3.19494 0 7.04555V56.3644C0 60.2127 3.22079 63.4062 7.10417 63.4099L48.6875 63.5063C49.2395 63.5064 49.784 63.3792 50.2778 63.1347L58.2014 69.506C58.7228 69.9259 59.3537 70.1908 60.0208 70.27C60.688 70.3493 61.3641 70.2396 61.971 69.9538C62.5778 69.6679 63.0905 69.2175 63.4496 68.6549C63.8087 68.0922 63.9995 67.4402 64 66.7745V7.04555C64 3.19494 60.7753 0 56.8889 0H7.11111ZM7.11111 7.04555H56.8889V59.3711L50.1667 53.97C49.6617 53.5648 49.0543 53.3044 48.4104 53.2172C47.7666 53.1299 47.1109 53.2191 46.5146 53.4752C45.9184 53.7312 45.4043 54.1442 45.0283 54.6693C44.6523 55.1945 44.4288 55.8117 44.3819 56.4538L7.11806 56.3644C7.11574 56.3644 7.11343 56.3644 7.11111 56.3644V7.04555ZM27.1528 18.1643C22.559 18.6011 19.0694 21.3224 19.0694 21.3224C19.0694 21.3224 14.9333 27.2306 14.2222 38.7712C18.4036 43.5058 24.7292 43.5324 24.7292 43.5324L26.0417 41.7985C24.7012 41.3406 23.5587 40.7531 22.3889 39.8583L22.6319 39.1427C24.8186 40.1326 27.6267 40.7114 32 40.7114C36.3733 40.7114 39.1814 40.1291 41.3681 39.1427L41.6111 39.8583C40.4378 40.7566 39.2952 41.3441 37.9583 41.7985L39.2708 43.5324C39.2708 43.5324 45.5964 43.5058 49.7778 38.7712C49.0667 27.2306 44.9306 21.3224 44.9306 21.3224C44.9306 21.3224 41.2277 18.4884 36.8472 18.1643L35.9444 19.9945C34.604 19.7514 33.2053 19.5748 32 19.5748C30.7556 19.5748 29.3572 19.7415 28.0417 19.967L27.1528 18.1643ZM25.5972 28.1822C27.1617 28.1822 28.4444 29.7604 28.4444 31.705C28.4444 33.6495 27.1617 35.2277 25.5972 35.2277C24.0328 35.2277 22.7569 33.6495 22.7569 31.705C22.7569 29.7604 24.0328 28.1822 25.5972 28.1822ZM38.4028 28.1822C39.9672 28.1822 41.2431 29.7604 41.2431 31.705C41.2431 33.6495 39.9672 35.2277 38.4028 35.2277C36.8383 35.2277 35.5556 33.6495 35.5556 31.705C35.5556 29.7604 36.8383 28.1822 38.4028 28.1822Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "dc62c67d-023c-4f23-82c7-1361e2a91ab1"
// title: ""
// type: :reference
// key: "header"
// parent_id: "e4900893-e582-4ccb-b39e-6f6faf26a258"
export function HeaderDc62c67d023c4f2382c71361e2a91ab1(props: any) {
  return (
    <SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a parentTag="HeaderDc62c67d023c4f2382c71361e2a91ab1" title="Join Us" {...props} />
  );
}

// id: "49bd763a-bdbe-420f-8d95-28413672fafc"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "60a7002d-6842-485c-a67a-c75f006013f4"
export const PostSlug49bd763aBdbe420f8d9528413672fafc = "home-hero";

// id: "995ad6a1-a13a-4023-a6b5-0e3266860bcd"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: "7e0fc815-b5de-4fd8-8fb8-e67fdd915e5d"
export function MarketingCards995ad6a1A13a4023A6b50e3266860bcd(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards995ad6a1A13a4023A6b50e3266860bcd", parentTag)} {...props} />
  );
}

// id: "61d07730-bdb8-4eb2-947a-40c6cd68c6e0"
// title: ""
// type: :reference
// key: "body"
// parent_id: "acf8d15d-2704-4b2f-a06f-c83b875ce6c6"
export function Body61d07730Bdb84eb2947a40c6cd68c6e0(props: any) {
  return (
    <MarketingBody7e0fc815B5de4fd88fb8E67fdd915e5d parentTag="Body61d07730Bdb84eb2947a40c6cd68c6e0" postSlug="about-page-posts" {...props} />
  );
}

// id: "22834b55-bf2c-47ab-8b0d-f583881f0f62"
// title: ""
// type: :text
// key: "class"
// parent_id: "e1aa5cdb-52e0-4fe0-b792-24ae7ac529c8"
export const Class22834b55Bf2c47ab8b0dF583881f0f62 = "linked-logo";

const Components = {
  SecondaryNavC634c71f089b485eB5a0B842921a2ad8,
  HeaderD333f7169dc140e888b1D85d8cbd1718,
  NavMenuSlugBa709bb62020478eBfba45ed8259c40b,
  Footer108095c19f604bee86d7Eccdcb5f88f6,
  SocialLinksC92a6ab9A20e40f4B6c77b8809ad78ba,
  Footer1650707563fe443d8f89Da661516444d,
  Header7c53b240D7b24fc596fb4b39593c6024,
  NavigationB452668eFc3f4149990a76381dd80a97,
  HeaderTop8b7a9ffc31cf4d99A273F4a8ed489f1b,
  ButtonClassE964e989Cc8246ca94012236a7319c9d,
  Logo5428f04678144166Bd5a9eb1acfb6dc2,
  Label4394f20cF6a449feB1ee5e333faf4b1f,
  BodyC673af5bF92846c7A1d01419afe9388d,
  HeaderEa6695e4366342d9925f0f48e206fbb4,
  Title40d6c90fC55b4f6292de9aae6546dd0e,
  Hero60a7002d6842485cA67aC75f006013f4,
  Title97a37c1c5d6f424085f994abf71aa79e,
  Footer9797e4e3521741c6B7edA4a44cac8250,
  NavMenuSlug9433db3dF27644acA0d184b9e8767457,
  Class8d9c278a1d9740eaBa2dCc5336b6eb3c,
  MembersAreaHeader91cd19b10cea4eacAbfb5bdac2d10edc,
  NavMenuSlugAd6e2ea52dbd42958797516767fc29c0,
  MembersAreaDefaultEd8d898cAb94496986dd983ed87762e5,
  Href4012ea1270d54d0f9965533409e890b1,
  AboutUsPageAcf8d15d27044b2fA06fC83b875ce6c6,
  LinksFf30079bB2e541ef805dC0472cff40de,
  LinkedLogo512dd7c3D7644e53Bcfd112887b86671,
  Url6feaa3ee6d0244cd9548156e1fa43795,
  NavMenuSlug891c5d72413a48359147D5e5a2fda088,
  ResourcesPage6630a8198c4d4200A8ba20da7da08cb9,
  LoginPage4726d98f6cfe48689a7cA03f2387636e,
  Header2c9386c8211f498fAbf61622624c3a15,
  PostSlugC5f61f18Ca76442b874e74ffc94c4996,
  NavMenuSlug0bbca8bd262643ea986aEa7808c489ec,
  PostSlug0581c345D0014cc6B9ce092f6625bd13,
  MarketingBody7e0fc815B5de4fd88fb8E67fdd915e5d,
  SignupPagesE4900893E5824ccbB39e6f6faf26a258,
  MemberPages7a34463dE2c042709a3157515ba44070,
  HeaderTop0f0b7bea9f9e4014928f920621b4c1a4,
  Header4e0e5cf18c834789B3f94bce0334fc3a,
  NavMenuSlug3dcb8a54233d4f6d83deBe8bb4ac6954,
  PasswordResetPage521f30d4Faa245e0B45c7263b7165e59,
  AdminPages5f28363cF5da4168B559E1a700081add,
  HomeHeader4dc8ec1a3744404d9a2fB3e922818db2,
  Title7bce57b220024e5fA03a54f2afdb1300,
  Title2e98b91eA0b74ba1A4190e2bc0d541c6,
  Title6c45c09d23604d3f874228800108d48d,
  Header50f8d515Db5948a3A192051a32a6cd8a,
  Label0b3a0921D85f4d8bAf06F9f4fea6e8b4,
  HomePageHero0e99f6d4141e43a7B8eb1b0602eab531,
  ShortHeaderBdfcdca46c8d4d0fA05fF20a772295b4,
  DefaultLogoB7cdc3fe0d5c42408df24b72ca7ea945,
  HeaderBcf66754494e412e8f7cA08a1a45188d,
  PageQuote307b52064f744f78B469B019dbbd8e21,
  FooterDd5f347cF132483c892cC1671cd996d0,
  SecondaryPageDbef23cc63454c00951a08ea58140265,
  Header089a7791F5b34d5fB437B1ba7eb5736e,
  HeaderTop4747cba120fb4d7590c6Dd448039ae48,
  MessagePages570dacacD4fe430499beD86c38cde521,
  JoinUsPageFb0b7ea4Cdef496aA00f4587935596b6,
  DefaultHeaderBca62c92F8bf41e3Bfd90b5bca8c343a,
  PublicEventsPageDb8795d693c74fc4830827309d51b12c,
  BackgroundImage6ee102a9Fbcb4c0d951cA112ef64f8be,
  Button0a929183Cc2a49ee81bb71b0bd7dd95b,
  AltFa4e28460ab34b87B3013b315ad08992,
  Header767354eb2cca4fe8B88704b43ee917f7,
  Copyright6aae4e26A5a74b7e9000A61dff8736e7,
  WelcomeBackA567f63982cf4e5e89a8A94c4d495528,
  Title0237d48bCceb4a4eB383E5653afb2068,
  Quote2b2f9877714f474fA8167a9637d853ee,
  TitleFea8e3b62a8447bcA3c752079088de67,
  TitleDd6e6f7184d9461d9b102f379926cde2,
  Label8873d4dbAbc24cee978b64a22fc57a92,
  HomePage6167d8ce37484b7689e36debc35c9cf8,
  Footer8254dca1F63048f1B27bE166419f1443,
  LinkedLogoE1aa5cdb52e04fe0B79224ae7ac529c8,
  Header827e670a5c9b413a88d91c5d531d71e0,
  Title5275fc9cEe8c4bda8011Cb162b5b99c5,
  SecondaryMarketingHeaderB035375fE8aa4d1a898893a34f52752a,
  Footer20c5fc9c2ade49c492aa3d6512785489,
  Navigation17a62ea762ec49a1A74cF34c5e4523a6,
  Title4acb01ff98a645799c3380bd5379f30b,
  SecondaryMarketingHeroD8be0745D5c245e7847924c6a00522e4,
  Header74b1bfbc6dd74db7Bf17661f3b4c227a,
  FooterFe03d1e289f84f62A811E95e1066127c,
  FooterA4f9f6156c0e42d5B4abA26550db5cdc,
  Href0ead4175158c46db9fb926e344c1ecfd,
  MarketingCards3614008991544977B2fe40700293dbe7,
  HeaderE915e08b792744c29c008e5bb73168bf,
  Hero92b4bb0bC6f84d138acfFc05780e060d,
  LabelA446b172Baeb430f81edA8bd6e9c6306,
  PostSlug2730eded900b439a90b9B39ccb6d4351,
  DefaultHeaderTopA1b211b211a24d8aAdc7Dd1c8dd23ede,
  Groupflow4304dcdfFda24270Bd542d77e07e2835,
  SocialLinksE9ae0ca8453946baBe6eD91ec86f99df,
  Title77d2f2f9D8c743e7964893c99e0fa3a7,
  DefaultFooter03dd003fF3554fb0B67923afec0b44f3,
  SocialLinks71f6c31a34dd44d5805e501d9b1e6db8,
  HeaderDc62c67d023c4f2382c71361e2a91ab1,
  PostSlug49bd763aBdbe420f8d9528413672fafc,
  MarketingCards995ad6a1A13a4023A6b50e3266860bcd,
  Body61d07730Bdb84eb2947a40c6cd68c6e0,
  Class22834b55Bf2c47ab8b0dF583881f0f62
}

export default Components;