const ROUTES = {
  "/": {
    "name": "HomePage6167d8ce37484b7689e36debc35c9cf8",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPages5f28363cF5da4168B559E1a700081add",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPageAcf8d15d27044b2fA06fC83b875ce6c6",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/events/public": {
    "name": "PublicEventsPageDb8795d693c74fc4830827309d51b12c",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPageFb0b7ea4Cdef496aA00f4587935596b6",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/login": {
    "name": "LoginPage4726d98f6cfe48689a7cA03f2387636e",
    "type": "HTML",
    "key": "login-page"
  },
  "/members/*": {
    "name": "MemberPages7a34463dE2c042709a3157515ba44070",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages570dacacD4fe430499beD86c38cde521",
    "type": "HTML",
    "key": "message-pages"
  },
  "/reset_password": {
    "name": "PasswordResetPage521f30d4Faa245e0B45c7263b7165e59",
    "type": "REFERENCE",
    "key": "password-reset-page"
  },
  "/resources": {
    "name": "ResourcesPage6630a8198c4d4200A8ba20da7da08cb9",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPagesE4900893E5824ccbB39e6f6faf26a258",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;